<template>

<div class="login" :class="{'is-success': is.success, 'is-failed': is.failed}">

	<app-icon icon="loading" class="login-loading" v-if="is.checking" />

	<div class="login-box" v-if="!is.checking">

		<div class="login-title">Reset password</div>

		<template v-if="is.success">

			<div class="login-text">Great, your password has now been reset. You can proceed with logging in.</div>

		</template>

		<template v-if="is.failed">

			<div class="login-text">{{ reason }}</div>

		</template>

		<template v-if="is.valid && !is.success">

			<div class="login-text">Hi, {{ name }}! Please enter your new password below and confirm it.</div>
			
			<app-input-text :noLine="true" :disabled="is.loading" notes="Your password must be at least 8 characters long, include lower and upper case letters, and at least one number." :stacked="true" ref="password" :password="true" v-model="model.new" placeholder="Enter password" maxlength="128" />
			<app-input-text :noLine="true" :disabled="is.loading" :stacked="true" ref="password2" :password="true" v-model="model.confirm" placeholder="Confirm password" maxlength="128" />

			<app-button text="Submit" class="login-button" v-on:click.native.stop="onSubmitClick" :disabled="!isValid" :loading="is.loading" />

		</template>

		<router-link :to="{ name: 'Login' }" class="login-link"><i class="fa fa-caret-left"></i> <span>back to login</span></router-link>

	</div>

</div>

</template>

<script>

import { validationMixin } from 'vuelidate';
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			is: {
				checking: true,
				loading: false,
				success: false,
				valid: false,
				failed: false
			},
			reason: '',
			model: {
				new: '',
				confirm: ''
			}
		}

	},

	validations: {
		model: {
			new: {
				required,
				minLength: minLength(8),
				containsUppercase: function(value) {
					return /[A-Z]/.test(value)
				},
				containsLowercase: function(value) {
					return /[a-z]/.test(value)
				},
				containsNumber: function(value) {
					return /[0-9]/.test(value)
				}
			},
			confirm: {
				required,
				sameAsPassword: sameAs('new')
			}
		}
	},

	created: function() {

		this.$api.get('login/reset', {
			key: this.$route.params.key
		}).then(function(json) {

			this.name = json.name
			this.is.valid = true
			this.is.checking = false

		}.bind(this), function() {

			this.is.failed = true
			this.is.checking = false
			this.reason = 'Sorry, this is an invalid reset request. If you clicked a link in an e-mail from us then please contact support@cardboardevents.com.'

		}.bind(this))

	},

	computed: {

		isValid: function() {

			return !this.$v.$invalid
		
		}

	},

	methods: {

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.post('login/reset', {
				password: this.model.new,
				key: this.$route.params.key
			}).then(function() {

				this.is.loading = false
				this.is.success = true

			}.bind(this), function() {

				this.is.loading = false
				this.is.failed = true
				this.reason = 'Sorry, something went wrong and your password could not be reset. Please try again.'

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.login {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #1e3054;
	padding-left: 50%;
	display: flex;
	align-items: center;
	transition: background-color 100ms linear;
}

.login-loading {
	color: #fff;
	position: absolute;
	font-size: 32px;
	left: 75%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.is-mobile .login-loading {
	left: 50%;
}

.login.is-success {
	background-color: #4faa80;
}

.login.is-failed {
	background-color: #c55b5b;
}

.login:before {
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	width: 50%;
	background-image: url(https://api.bigbox.dev/files/uploads/62e2ed3b99bbe.jpg);
	background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #2f3a4a;
    filter: sepia(100%) hue-rotate(190deg) saturate(400%) brightness(50%);
}

.is-mobile .login {
	padding-left: 0%;
	display: block;
}

.is-mobile .login:before {
	width: 100%;
}

.login-box {
	width: 365px;
	border-radius: 0px 10px 10px 0px;
	padding: 40px;
}

.is-mobile .login-box {
	position: absolute;
	left: 0px;
	bottom: 0px;
	background-color: #1e3054;
	width: 100%;
	max-width: 100%;
	padding: 20px;
	border-radius: 0px;
}

.login-title {
	color: #fff;
	font-size: 24px;
	margin-bottom: 18px;
}

.login-text {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}

.login >>> .input-notes {
	color: rgba(255, 255, 255, 0.5);
}

.login-convention {
	display: flex;
	margin-bottom: 10px;
	align-items: center;
	background-color: #fff;
	border-radius: 10px;
}

.login-convention-logo {
	width: 64px;
	height: 64px;
	border: 5px solid #fff;
	border-radius: 10px;
	background-size: contain;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-image: url(https://static.wixstatic.com/media/c05de0_f353a33ed0ea482994f74f208189e142~mv2_d_1372_1474_s_2.gif);
}

.login-convention-text {
	color: #1e3054;
}

.login-convention-text-name {
	font-size: 20px;
	font-weight: 600;
}

.login-convention-text-date {
	font-size: 14px;
	opacity: 0.75;
	margin-top: 3px;
}

.login-button {
	width: 100%;
	margin-top: 20px;
}

.login-link {
	font-size: 12px;
	color: #fff;
	margin-top: 20px;
	letter-spacing: 1px;
	cursor: pointer;
	text-transform: lowercase;
	display: block;
	opacity: 0.75;
}

.login-link:hover {
	opacity: 1;
}

.login-link:hover span {
	text-decoration: underline;
}

</style>
